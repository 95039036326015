import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { globalCompanyName } from "../../assets/constants";

export const fetchFilterData = createAsyncThunk(
	"filter/fetchFilterData",
	async () => {
		const { data } = await axios({
			url: "https://crm-s.com/api/v1/employees-filters-data",
			params: {
				global_company_name: globalCompanyName,
			},
		});
		return data.data;
	}
);

const initialState = {
	departments: [],
	positions: [],
	skills: [],
	languages: [],
	tags: [],
	loading: false,
	error: null,
	isFilterActive: false,
	isFiltered: false,
};

export const filterSlice = createSlice({
	name: "filter",
	initialState,
	reducers: {
		handleFilter: (state) => {
			state.isFilterActive = !state.isFilterActive;
		},
		handleFilterState: (state, action) => {
			console.log("filtered =>", action.payload);

			state.isFiltered = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchFilterData.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchFilterData.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.departments = action.payload.departments;
				state.positions = action.payload.positions;
				state.skills = action.payload.skills;
				state.languages = action.payload.languages;
				state.tags = action.payload.tags;
			})
			.addCase(fetchFilterData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});
export const { setFilterValue, handleFilter, handleFilterState } =
	filterSlice.actions;

export default filterSlice.reducer;
