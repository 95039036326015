import {useEffect, useState} from "react";
import stl from './cv.module.css'

function Education({data, dateFormatting}) {

    const [eduItems, setEduItems] = useState(data)
    useEffect(() => {
        setEduItems(Object.values(data).map(i => {
            return i.end_date_unix
                ? {
                    ...i,
                    end_date_unix: dateFormatting(i.end_date_unix),
                    start_date_unix: dateFormatting(i.start_date_unix)
                }
                : {...i, end_date_unix: 'Present', start_date_unix: dateFormatting(i.start_date_unix)}
        }))
    }, [data])

    return (
        <div className={stl.eduWrapper}>
            <span>education</span>
            <div className={stl.eduContainer}>
                {eduItems
                    // .sort((a, b) => b.end_date_unix - a.end_date_unix)
                    .map((item, index) => {
                        return (
                            <article className={stl.eduItem} key={index}>
                                    <span className={stl.eduItem_name}>
                                <b>{item.institution_name}</b>
                                <i>
                                    {item.start_date_unix} - {item.end_date_unix}
                                </i>
                            </span>
                                <span className={stl.eduItem_grade}>
                                <b>{item.degree_name}</b>
                                    {` of `}
                                    <b>{item.specialisation}</b>
                            </span>
                                <span className={stl.eduItem_fields}>
                                {`Education fields: `}
                                    {item.education_tags.map((item, index) => <i key={index}>{item}</i>)}
                            </span>
                            </article>
                        )
                    })
                }
            </div>
        </div>
    )
}

export {Education}