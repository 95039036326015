import {Link} from "react-router-dom";
import stl from './styles/404.module.css'

function NothingFoundPage(){
    return(
        <section className={stl.messageCard}>
            <span>Oops...🙃</span>
            <span>something went wrong</span>
            <span>Please try again or back to <Link to='/'> Home page </Link> </span>
        </section>
    )
}

export {NothingFoundPage}