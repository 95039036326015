export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('selectedStaff');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    localStorage.setItem('selectedStaff', JSON.stringify(state));
};