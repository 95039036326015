import React, { useRef, useEffect, useState } from "react";
import { Card } from "../Card";
import { FilterSelect } from "../Filter";
import { useDispatch, useSelector } from "react-redux";
import { getCards, getMoreCards } from "../../Redux/slices/cardsSlice";
import { FrownTwoTone } from "@ant-design/icons";

import stl from "./styles/home.module.css";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

function HomePage() {
	const { cards, isLoading, cardsLength, totalEmployees } = useSelector(
		(state) => state.cardSlice
	);
	const { isFiltered } = useSelector((store) => store.filterSlice);
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const loader = useRef();
	const [loaderState, setLoaderState] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (
					entry.isIntersecting &&
					cards.length < totalEmployees &&
					!isFiltered
				) {
					dispatch(getMoreCards());
				} else if (entry.isIntersecting) {
					observer.unobserve(loader.current);
					console.log(loader.current);
					setLoaderState(true);
				}
			},
			{ rootMargin: "0px 0px 100% 0px" }
		);

		if (loader.current) {
			observer.observe(loader.current);
		}

		return () => observer.disconnect();
	}, [cards.length, dispatch, totalEmployees]);

	if (isLoading) {
		const arr = new Array(cardsLength).fill("", 0, cardsLength);
		return (
			<div className={stl.wrapper}>
				{arr.map((item, i) => (
					<Card key={i} data={""} />
				))}
			</div>
		);
	}

	return (
		<>
			<div className={stl.wrapper}>
				{cards.length > 0 ? (
					cards.map((item, i) => <Card key={i} data={item} />)
				) : (
					<div className={stl.noStuff}>
						<i>
							<FrownTwoTone />
						</i>
						Sorry, there are no staff matching your requirements
						<Button
							className={stl.goHome}
							type="primary"
							htmlType="button"
							onClick={() => dispatch(getCards())}>
							Return to all staff
						</Button>
					</div>
				)}
				<FilterSelect />
			</div>
			{cards.length > 0 && (
				<div
					className={loaderState ? stl.staffLoaded : stl.loadingChecker}
					ref={loader}>
					{loaderState && (
						<span>
							{" "}
							For now, that's all. <br /> If you have not found a suitable
							candidate, <b>write to us</b> and we will gladly try to help you!
						</span>
					)}
				</div>
			)}
		</>
	);
}

export { HomePage };
