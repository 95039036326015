import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
	POST_URL,
	globalCompanyName,
	projectCompany,
	promocode,
	type,
} from "../../assets/constants";

const initialParams = {
	global_company_name: globalCompanyName,
	project_company: projectCompany,
	promocode: promocode,
	type: type,
};

export const postFormData = createAsyncThunk(
	"form/postFormData",
	async ({ name, phone, email, note }) => {
		try {
			const { data } = await axios({
				url: POST_URL,
				method: "POST",
				params: {
					...initialParams,
					client_name: name,
					client_phone: phone,
					client_email: email,
					note: note,
				},
			});
			return data.data;
		} catch (error) {
			return Promise.reject(error);
		}
	}
);

export const formSlice = createSlice({
	name: "form",
	initialState: {
		isLoading: false,
		isError: false,
		message: "",
	},
	reducers: {
		resetState: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.message = "";
		},
	},
	extraReducers: {
		[postFormData.pending]: (state, action) => {
			state.isLoading = true;
			state.isError = false;
			state.message = "Sending...";
		},
		[postFormData.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.message = "Your message has been successfully delivered. Our managers will contact you soon";
		},
		[postFormData.rejected]: (state, action) => {
			console.log("error");
			state.isLoading = false;
			state.isError = true;
			state.message = `An error occured: ${action.error.message}`;
		},
	},
});

export const { resetState } = formSlice.actions;

export default formSlice.reducer;
