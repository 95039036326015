import React, { useCallback, useEffect, useState } from "react";
import {
	Button,
	Modal,
	Input,
	Tooltip,
	Form,
	Space,
	Row,
	Col,
	message,
} from "antd";
import {
	SolutionOutlined,
	InfoCircleOutlined,
	UserOutlined,
	MailOutlined,
	PhoneOutlined,
} from "@ant-design/icons";
import { StyleProvider } from "@ant-design/cssinjs";
import stl from "./modal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { postFormData, resetState } from "../../Redux/slices/formSlice";
import { priceCoeff } from "../../assets/constants";

const { TextArea } = Input;

export function ModalWin() {
	const [open, setOpen] = useState(false);
	const formRef = React.useRef(null);
	const cartItems = useSelector((store) => store.cartSlice.selectedStaff);
	const {
		isLoading,
		message: msg,
		isError,
	} = useSelector((store) => store.formSlice);
	const dispatch = useDispatch();

	const showModal = useCallback(() => {
		setOpen(true);
	}, []);
	const handleCancel = useCallback(() => {
		setOpen(false);
		formRef.current?.resetFields();
	}, []);

	const onReset = useCallback(() => {
		formRef.current?.resetFields();
	}, []);

	const handleSubmit = useCallback(
		(values) => {
			const temp = { message: "", staff: "" };

			temp.staff = cartItems
				.map((item) => {
					return `name: ${item.short_name}, id: ${item.employee_id}, position: ${item.position_name}`;
				})
				.join(" ◀┝━━━━━━┥▶ ");

			if (values.hasOwnProperty("note")) {
				const msg = values.note;
				temp.message = msg;
			}
			const note = `█ Client says: ${temp.message} █ Client coeff = ${priceCoeff} █ Client choice: ${temp.staff} █ `;

			console.log(note);

			const data = {
				name: values.name,
				phone: values.phone,
				email: values.email,
				note: note,
			};
			dispatch(postFormData(data));
		},
		[cartItems, dispatch]
	);

	useEffect(() => {
		if (isLoading) {
			message.open({
				type: "loading",
				content: msg,
				duration: 0,
			});
		}
		return () => {
			message.destroy();
		};
	}, [isLoading, msg]);

	useEffect(() => {
		if (isError) {
			message.open({
				type: "error",
				content: msg,
				onClose: () => dispatch(resetState()),
			});
		}
		return () => {
			message.destroy();
		};
	}, [isError, msg]);

	useEffect(() => {
		if (!isError && !isLoading && msg.length > 0) {
			setOpen(false);
			message.open({
				type: "success",
				content: msg,
				duration: 0,
				onClose: () => dispatch(resetState()),
				style: {
					marginTop: "4rem",
				},
			});
		}
		return () => {
			message.destroy();
		};
	}, [isError, isLoading, msg]);

	return (
		<>
			<StyleProvider hashPriority="high">
				<Button
					onClick={showModal}
					block
					type="primary"
					size="large"
					icon={<SolutionOutlined />}
					className={stl.bookStaffBtn}>
					Book Staff
				</Button>
				<Modal
					title="Book Staff"
					open={open}
					centered
					onCancel={handleCancel}
					footer={[]}
					confirmLoading={isLoading}>
					<Form
						ref={formRef}
						noValidate
						layout="vertical"
						onFinish={handleSubmit}
						initialValues={{
							// name: "tqst",
							// email: "tqst@test.com",
							// phone: "380123456789",
							note: "",
						}}>
						<Form.Item
							label="Name"
							required
							name="name"
							rules={[
								{
									type: "string",
									pattern: "^[a-zA-Zа-яА-Яs]+$",
									message: "Enter a valid name!",
								},
								{
									required: true,
									message: "Enter your name!",
								},
							]}>
							<Input
								placeholder="Enter your name"
								prefix={<UserOutlined />}
								suffix={
									<Tooltip title="How may we address you?">
										<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
									</Tooltip>
								}
							/>
						</Form.Item>
						<Form.Item
							label="Email"
							name="email"
							dependencies={["phone"]}
							rules={[
								{
									type: "email",
									pattern:
										"^([a-z0-9_-]+.)*[a-z0-9_-]+@[a-z0-9_-]+(.[a-z0-9_-]+)*.[a-z]{2,6}$",
									message: "Enter a valid email!",
								},
								{
									required: true,
									message: "Enter your email!",
								},
							]}>
							<Input
								placeholder="Enter your email"
								type="email"
								prefix={<MailOutlined />}
								suffix={
									<Tooltip title="You can fill up either email or phone number only.">
										<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
									</Tooltip>
								}
							/>
						</Form.Item>
						<Form.Item
							label="Phone"
							name="phone"
							rules={[
								{
									required: false,
								},
								{
									validator(_, value) {
										if (!value) {
											return Promise.resolve();
										} else if (!Number(value)) {
											return Promise.reject("Use only numbers!");
										} else {
											return Promise.resolve();
										}
									},
								},
								{
									min: 10,
									message: "Number is too short!",
								},
								{
									max: 12,
									message: "Number is too long!",
								},
							]}>
							<Input
								placeholder="Enter your phone number"
								name="phone"
								prefix={<PhoneOutlined />}
								suffix={
									<Tooltip title="Use only numbers. You can fill up either email or phone number only.">
										<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
									</Tooltip>
								}
							/>
						</Form.Item>
						<Form.Item label="Note" name="note">
							<TextArea rows={4} showCount maxLength={200} />
						</Form.Item>
						<Form.Item>
							<Row>
								<Col flex={"auto"}>
									<Space size={"middle"}>
										<Button
											type="default"
											htmlType="button"
											onClick={handleCancel}>
											Cancel
										</Button>
										<Button type="text" htmlType="button" onClick={onReset}>
											Reset
										</Button>
									</Space>
								</Col>
								<Col>
									<Button type="primary" htmlType="submit" loading={isLoading}>
										Submit
									</Button>
								</Col>
							</Row>
						</Form.Item>
					</Form>
				</Modal>
			</StyleProvider>
		</>
	);
}
