
import {Routes, Route} from "react-router-dom";
import {Layout} from "./layout";
import {useEffect} from "react";

import{useDispatch} from "react-redux";

import {getCards} from '../Redux/slices/cardsSlice'
import {fetchFilterData} from "../Redux/slices/filterSlice";

import{HomePage} from './pages/Home'
import{NewsPage} from "./pages/News";
import{FAQPage} from "./pages/FAQ";
import{CartPage} from "./pages/Cart";
import{NothingFoundPage} from "./pages/404";
import {CV} from './CV'

function App() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchFilterData());
        dispatch(getCards())
    }, [dispatch]);


    console.log('render App')

    return (
            <Routes>
                <Route path='/' element={<Layout/>}>
                    <Route path=':slug' element={<CV/>}/>
                    <Route index element={<HomePage/>}/>
                    <Route path='news' element={<NewsPage/>}/>
                    <Route path='faq' element={<FAQPage/>}/>
                    <Route path='cart' element={<CartPage/>}/>
                    <Route path='404' element={<NothingFoundPage/>}/>
                </Route>
            </Routes>
    );
}

export default App;
