import {useEffect, useState} from "react";
import stl from './card.module.css'

function Skills ({data}) {

    const skillArr = data
        ? data.split(',')
        : [];

    const showCount = 5;
    const hiddenCount = skillArr.length - showCount;

    const skillRow = skillArr.length > showCount
        ? skillArr.slice(0, showCount)
        : skillArr


    return (
        <>
            {skillRow.length > 0
                ? skillRow.map((item, i) => <s key={i}>{item}</s>)
                : <s className={stl.vacant}>Skills not yet added</s>
            }
            {hiddenCount > 0 && <s className={stl.hiddenCounter}>{hiddenCount}+</s>}
        </>
    )
}

export {Skills}