import { createSlice } from "@reduxjs/toolkit";
import { loadState, saveState } from "../localStorage";

const initialState = {
	selectedStaff: loadState() ? loadState() : [],
};

function updateStorage(state) {
	saveState(state.selectedStaff);
	console.log("store updated");
}

export const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		createStaffItem: (state, action) => {
			const {
				department,
				employee_id,
				slug,
				position_name,
				short_name,
				image_url,
				full_price,
				full_symbol,
			} = action.payload;

			state.selectedStaff.push({
				department,
				employee_id,
				slug,
				position_name,
				short_name,
				image_url,
				full_price,
				full_symbol,
			});
			updateStorage(state);
		},
		removeStaffItem: (state, action) => {
			state.selectedStaff = state.selectedStaff.filter(
				(obj) => obj.employee_id !== action.payload
			);
			updateStorage(state);
		},
	},
});
export const { createStaffItem, removeStaffItem } = cartSlice.actions;

export default cartSlice.reducer;
