import {useEffect, useState} from "react";
import stl from './cv.module.css'

function Experiense({data, dateFormatting}) {

    const [expItems, setExpItems] = useState(data)
    useEffect(() => {
        setExpItems(Object.values(data).map(i => {
            return i.end_date_unix
                ? {
                    ...i,
                    end_date_unix: dateFormatting(i.end_date_unix),
                    start_date_unix: dateFormatting(i.start_date_unix)
                }
                : {...i, end_date_unix: 'Present', start_date_unix: dateFormatting(i.start_date_unix)}
        }))
    }, [data])

    return (
        <div className={stl.expWrapper}>
            <span>work experience</span>
            <div className={stl.expContainer}>
                {expItems
                    // .sort((a, b) => b.end_date_unix - a.end_date_unix)
                    .map((item, index) => {
                        return (
                            <article className={stl.expItem} key={index}>
                                <span className={stl.expItem_name}>
                                <b>{item.company_name}</b>
                                <i>
                                    {item.start_date_unix} - {item.end_date_unix}
                                </i>
                            </span>
                                <span className={stl.expItem_position}>
                                <b>{`Position: `}</b> {item.position}
                            </span>
                                <p className={stl.expItem_description}>
                                    {item.responsibilities_text}
                                </p>
                                {item.responsibilities.length !== 0 && (<span className={stl.expItem_resps}>
                                {`Position responsibilities: `}
                                    {item.responsibilities.map((item, index) => <i key={index}>{item}</i>)}
                            </span>)}
                            </article>
                        )
                    })
                }
            </div>
        </div>
    )
}

export {Experiense}


// const Experience_obj = { TODO: what fields are required?
//     company_name: 'Commodity Exchange "Donetsk Universal Commodity Exchange"',
//     position: 'computing center technician',
//     start_date: '2003-01-01T00:00:00.000000Z',  TODO: Delete this row
//     end_date: '2007-01-01T00:00:00.000000Z',  TODO: Delete this row
//     responsibilities: [ TODO: need to understand what will be better - empty array or null ----null
//         'data collection',
//         'data selection',
//         'manage databases',
//         'recover database'
//     ],
//     experience_tags: [ TODO: need to understand what will be better - empty array or null ----null
//         'Commodity science ',
//         'IT, computers & Internet'
//     ],
//     responsibilities_text: 'Maintenance and technical support of servers and workstations of the exchange, network equipment and internal networks, Digitization, processing and updating of large data sets', TODO: what about formatting there?
//     start_date_unix: 1041379200, TODO: rename and send [unix*1000]
//     end_date_unix: 1167609600 TODO: rename and send [unix*1000]
// }