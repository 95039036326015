import stl from "./styles/cart.module.css";
import { useSelector, useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { removeStaffItem } from "../../Redux/slices/cartSlice";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { ModalWin } from "../Modal";
import { priceCoeff } from "../../assets/constants";

function CartPage() {
	const cartItems = useSelector((store) => store.cartSlice.selectedStaff);
	console.log(cartItems);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<div className={stl.wrapper}>
			{cartItems.length > 0 ? (
				<>
					{cartItems.map((item) => {
						return (
							<section className={stl.employeeRow} key={item.employee_id}>
								<picture>
									<LazyLoadImage
										alt={`${item.short_name} - ${item.position_name}`}
										effect="blur"
										src={item.image_url}
									/>
								</picture>
								<div className={stl.idents}>
									<span>
										<b>{item.short_name}</b>
									</span>
									<span>{item.position_name}</span>
									<span>{item.employee_id}</span>
								</div>
								<div className={stl.price}>
									<span>
										<b>Full price</b>
									</span>
									<span>
										{item.full_price * priceCoeff} {item.full_symbol}
									</span>
								</div>
								<div className={stl.buttons}>
									<Button
										block
										type="primary"
										ghost
										onClick={() => navigate(`/${item.slug}`)}
										icon={<EyeOutlined />}
										size="large">
										Watch CV
									</Button>
									<Button
										block
										danger
										type="primary"
										ghost
										onClick={() => dispatch(removeStaffItem(item.employee_id))}
										icon={<DeleteOutlined />}
										size="large">
										Remove
									</Button>
								</div>
							</section>
						);
					})}
					<ModalWin />
				</>
			) : (
				<section className={stl.noEmployees}>
					<em>
						Staff has not yet been selected.
						<br /> Try to find and book it <Link to={"/"}>HERE</Link>
					</em>
				</section>
			)}
		</div>
	);
}

export { CartPage };
