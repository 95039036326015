/*----------- FORM CONFIG ---------*/
const POST_URL = "https://crm-s.com/api/v1/leads-public";
const TEST_URL = "https://crm.rhelpers.com/api/v1/leads-public";
const globalCompanyName = "Remote Helpers"; // "Worksuite Demo Company"
const projectCompany = "rh-s.com";
const promocode = "T42MXKAMM2ZV";
const type = "sourceAffiliate";

/*------- CARDS CONFIG -------*/
const priceCoeff = 1.2; // price increase factor
const CARDS_URL = "https://crm-s.com/api/v1/employees-short-list";

export {
	POST_URL,
	TEST_URL,
	globalCompanyName,
	projectCompany,
	promocode,
	type,
	priceCoeff,
	CARDS_URL,
};
