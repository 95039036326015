import {useNavigate} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {Skills} from "./Skills";

import stl from './card.module.css'
import 'react-lazy-load-image-component/src/effects/blur.css';
import { priceCoeff } from "../../assets/constants";

function Card({data}) {
    const navigate = useNavigate()
    function viewCv() {
        navigate(data.slug)
    }

    if (!Object.values(data).length) {
        return (
            <article className={stl.card_skeleton}>
                <header>
                    <span></span>
                </header>
                <section className={stl.skeleton_avatar}>
                    <div></div>
                    <span></span>
                </section>
                <section className={stl.skeleton_idents}>
                    <div></div>
                    <div></div>
                </section>
                <section className={stl.skeleton_skills}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </section>
                <footer>
                    <hr/>
                    <span></span>
                    <hr/>
                </footer>
            </article>
        )
    }

    return (
        <article className={stl.card}>
            <header>
                <span><sup>{data.full_symbol}</sup>{data.full_price * priceCoeff}</span>
                <span></span>
            </header>
            <section className={stl.avatar} onClick={viewCv}>
                <picture>
                    <LazyLoadImage
                        alt={`${data.short_name} - ${data.position_name}`}
                        effect="blur"
                        src={data.image_url}
                    />
                </picture>
                <span>id: {data.employee_id}</span>
            </section>
            <section className={stl.idents} onClick={viewCv}>
                <h3>{data.short_name}</h3>
                <span>{data.position_name}</span>
            </section>
            <section className={stl.skills}>
                <div className={stl.wrapper}>
                    <Skills data={data.skills_string}/>
                </div>
            </section>
            <footer onClick={viewCv}>
                <hr/>
                <span>
                    View Profile
                </span>
                <hr/>
            </footer>
        </article>
    )
}

export {Card}