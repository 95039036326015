import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import { CARDS_URL, globalCompanyName } from '../../assets/constants';


const initialState = {
    cards: [],
    isLoading: true,
    globalCompany: globalCompanyName,
    startPosition: 0,
    cardsLength: 20,
    cardsUrl: CARDS_URL,
    totalEmployees: 0,
}

export const getCards = createAsyncThunk(
    'cards/fetchCards',
    async () => {
        const {data} = await axios({
                url: 'https://crm-s.com/api/v1/employees-short-list',
                params: {
                    global_company_name: initialState.globalCompany,
                    start: initialState.startPosition,
                    length: initialState.cardsLength
                }
            }
        )
        return data.data;
    }
)

export const getMoreCards = createAsyncThunk(
    'cards/fetchMoreCards',
    async (_,thunkAPI) => {
        const {cardSlice} = thunkAPI.getState();
        const {data} = await axios({
                url: cardSlice.cardsUrl,
                params: {
                    global_company_name: cardSlice.globalCompany,
                    start: cardSlice.cards.length,
                    length: cardSlice.cardsLength
                }
            }
        )

        return data.data;
    }
)

export const getFilteredCards = createAsyncThunk(
    'cards/fetchFilteredCards',
    async (params, thunkAPI) => {
        const {cardSlice} = thunkAPI.getState();
        const {data} = await axios({
                url: 'https://crm-s.com/api/v1/employees-short-list',
                params: {
                    start: 0,
                    length: -1,
                    global_company_name: cardSlice.globalCompany,
                    department_ids: JSON.stringify([...params.category]),
                    position_ids: JSON.stringify([...params.position]),
                    skill_ids: JSON.stringify([...params.skills]),
                    lang_ids: JSON.stringify([...params.languages]),
                    domain_ids: JSON.stringify([...params.tags]),
                    domain_type: params.domain_type
                }
            }
        )
        return data.data;
    }
)




export const cardSlice = createSlice({
    name: 'cards',
    initialState,
    reducers: {

    },
    extraReducers: {
        [getCards.fulfilled]: (state, action) => {
            console.log('get cards')
            state.cards = action.payload.employees;
            state.totalEmployees = action.payload.total;
            state.isLoading = false;
        },
        [getMoreCards.fulfilled]: (state, action) => {
            console.log(action.payload)
            console.log(state.cards.length)
            state.cards = [...state.cards, ...action.payload.employees];
            state.totalEmployees = action.payload.total;
        },
        [getFilteredCards.fulfilled]: (state, action) => {
            console.log('get filtered cards')
            state.cards = action.payload.employees;
        }

    }
})

// Action creators are generated for each case reducer function
export const { writeState } = cardSlice.actions

export default cardSlice.reducer