import {useEffect, useRef, useState} from "react";
import {useSelector, useDispatch} from 'react-redux';
import Select from 'react-select';
import stl from './filter.module.scss'
import {handleFilter, handleFilterState} from "../../Redux/slices/filterSlice";
import {getFilteredCards} from "../../Redux/slices/cardsSlice";
import {getCards} from "../../Redux/slices/cardsSlice";

const FilterSelect = () => {
    const dispatch = useDispatch()
    const {departments, positions, skills, languages, tags, isFilterActive} = useSelector(state => state.filterSlice);
    /*Options mapping*/
    const categoryOptions = departments.map(item => ({
        value: item.id,
        label: item.name
    }));
    const positionOptions = positions.map((item) => ({
        value: item.id,
        label: item.name,
        dep_id: +item.department_id
    }));
    const skillsOptions = skills.map((item) => ({
        value: item.id,
        label: item.name
    }));
    const languageOptions = languages.map((item) => ({
        value: item.id,
        label: item.name
    }));
    const tagsOptions = tags.map((item) => ({
        value: item.id,
        label: item.name
    }));
    /*statements*/
    const [initialFilterState, setInitialFilterState] = useState(false);
    const [filteredPositions, setFilteredPositions] = useState([])
    const [categoryActivity, setCategoryActivity] = useState(false)
    /*for animation*/
    const filterCanvas = useRef();
    const showAnimation = {
        opacity: [0, 1],
        left: ['-100%', 0], // Shorthand for [ 0, 0.8, 1 ]
    };
    const hideAnimation = {
        opacity: [1, 0],
        left: [0, '-100%'], // Shorthand for [ 0, 0.8, 1 ]
    };
    const animationProps = {
        duration: 300,
        iterations: 1,
        fill: 'forwards',
        easing: 'ease'
    };

    const [selectedValues, setSelectedValues] = useState({
        category: [],
        position: [],
        skills: [],
        languages: [],
        tags: []
    });
    const [selectedDomains, setSelectedDomains] = useState('both');

    useEffect(() => {
        if (initialFilterState) {
            filterCanvas.current.animate((isFilterActive ? showAnimation : hideAnimation), animationProps);
        } else {
            setInitialFilterState(true)
        }
    }, [isFilterActive])

    useEffect(() => {
        // Check if selectedValues.position is empty
        const isPositionEmpty = selectedValues.position.length === 0;

        // Block category select if position select is not empty
        setCategoryActivity(!isPositionEmpty);
    }, [selectedValues.position]);


    const handleChange = (value, action) => {
        if (action.name === "category") {
            // если выбраны значения в селекте "категории",
            // фильтруем список должностей по соответствующему id категории
            setFilteredPositions(
                positionOptions.filter( option => value.map(item => item.value).includes(option.dep_id))
            );
        }

        setSelectedValues((prevValues) => ({
            ...prevValues,
            [action.name]: value,
        }));

        // если селект "категории" пустой, а селект "должностей" не пустой,
        // блокируем селект "категории"
        if (action.name === "position") {
            const isCategoryDisabled = value.length > 0;
            setCategoryActivity(isCategoryDisabled);
        }
    };
    const getResults = () => {

        const newObj = {};
        for (const key in selectedValues) {
            if (Array.isArray(selectedValues[key])) {
                newObj[key] = selectedValues[key].map(item => item.value);
            } else {
                newObj[key] = selectedValues[key];
            }
        }

        const hasNonEmptyArray = () => {
            for (const key in selectedValues) {
                if (Array.isArray(selectedValues[key]) && selectedValues[key].length > 0) {
                    return true;
                }
            }
            return false;
        }

        newObj.tags.length > 0 && (newObj.domain_type = selectedDomains);

        hasNonEmptyArray()
            ? dispatch(getFilteredCards(newObj))
            : dispatch(getCards())

        dispatch(handleFilter())
        dispatch(handleFilterState(hasNonEmptyArray()));
        filterCanvas.current.animate(hideAnimation, animationProps)
    }
    const clearAll = () => {
        setSelectedValues({
            category: [],
            position: [],
            skills: [],
            languages: [],
            tags: []
        })
        setSelectedDomains('both')
    }
    const handleDomains = event => {
        setSelectedDomains(event.target.value)
    }



    return (
        <section className={stl.filter_canvas} ref={filterCanvas}>
            <h1>Choose the options you are interested in</h1>

            <Select
                isMulti
                name={'category'}
                placeholder={'Employee category'}
                options={categoryOptions}
                isDisabled={categoryActivity}
                value={selectedValues.category}
                onChange={handleChange}
            />
            <Select
                isMulti
                name={'position'}
                placeholder={'Employee positions'}
                options={filteredPositions.length ? filteredPositions : positionOptions}

                value={selectedValues.position}
                onChange={(value, action) => handleChange(value, action)}
            />
            <Select
                isMulti
                name={'skills'}
                placeholder={'Employee skills'}
                options={skillsOptions}

                value={selectedValues.skills}
                onChange={(value, action) => handleChange(value, action)}
            />
            <Select
                isMulti
                name={'languages'}
                placeholder={'Employee languages'}
                options={languageOptions}

                value={selectedValues.languages}
                onChange={(value, action) => handleChange(value, action)}
            />

            <fieldset className={stl.domainFieldset}>
                <legend>Employee domain</legend>
                <p>Choose a domain in which the employee has specialized education or practical experience.</p>

                <Select
                    isMulti
                    name={'tags'}
                    options={tagsOptions}
                    placeholder={'Employee domain'}

                    value={selectedValues.tags}
                    onChange={(value, action) => handleChange(value, action)}
                />

                <div className={stl.domainTypes}>
                    <div>
                        <input
                            type="radio"
                            id='both'
                            name='domain_type'
                            value='both'
                            checked={selectedDomains === 'both'}
                            onChange={handleDomains}
                        />
                        <label htmlFor="both">Both</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id='experience'
                            name='domain_type'
                            value='exp'
                            checked={selectedDomains === 'exp'}
                            onChange={handleDomains}
                        />
                        <label htmlFor="experience">Experience</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id='education'
                            name='domain_type'
                            value='edu'
                            checked={selectedDomains === 'edu'}
                            onChange={handleDomains}
                        />
                        <label htmlFor="education">Education</label>
                    </div>

                </div>

            </fieldset>

            <div className={stl.filterButtons}>
                <button onClick={clearAll}>Clear All</button>
                <button onClick={getResults}>Filter</button>
            </div>
        </section>
    );
};

export {FilterSelect}
