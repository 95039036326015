import { configureStore } from "@reduxjs/toolkit";
import cardSlice from "./slices/cardsSlice";
import filterSlice from "./slices/filterSlice";
import cartSlice from "./slices/cartSlice";
import formSlice from "./slices/formSlice";

export const store = configureStore({
	reducer: {
		cardSlice,
		filterSlice,
		cartSlice,
		formSlice,
	},
});
