import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import stl from './cv.module.css'
import {createStaffItem} from "../../Redux/slices/cartSlice";
import { priceCoeff } from "../../assets/constants";


function Pricing({pricing, data}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const selectedStaff = useSelector((state) => state.cartSlice.selectedStaff);

    const options = Object.values(pricing);
    const [activeOption, setActiveOption] = useState(pricing.full.name);
    const [activePrice, setActivePrice] = useState({})

    useEffect(() => {
        setActiveOption(pricing.full?.name)
        setActivePrice({...pricing.full});
    },[pricing])
    useEffect(() => {
        setActivePrice(options.find((item) => item.name === activeOption));
    },[activeOption])


    const handleBookInterview = () => {
        const existingStaff = selectedStaff.find((staff) => staff.employee_id === data.employee_id);
        if (existingStaff) {
            return;
        }
        dispatch(createStaffItem(data));
    }


    return(
        <section className={stl.pricingSection}>
            <div className={stl.selector}>
                {options.map((item, i) =>
                    <button
                        key={i}
                        onClick={() => setActiveOption(item.name)}
                        className={item.name === activeOption ? `${stl.active}`: ''}
                    >
                        {item.name}
                    </button>)}
            </div>
            <div className={stl.priceDetails}>
                <span className={stl.priceRow}>
                    <sup>{activePrice.symbol}</sup>
                    {activePrice.price * priceCoeff}
                </span>
                <span>per month</span>
                <span className={stl.priceDetails}>{activePrice.hours} working hours (~{activePrice.symbol}{(activePrice.price / activePrice.hours).toFixed(2)} per hour)</span>
            </div>


            {selectedStaff.some(obj => obj.employee_id === data.employee_id)
            ? <button className={stl.bookBtn_inCart} onClick={() =>navigate('/cart')}>already in cart</button>
            : <button onClick={handleBookInterview}  className={stl.bookBtn}>book an interview</button>
            }


        </section>
    )
}

export {Pricing}


// onClick={handleBookInterview}