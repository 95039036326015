import axios from "axios";
import stl from "./cv.module.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player/youtube";

import { IoPlayCircleOutline, IoCloseCircleOutline } from "react-icons/io5";

/*Components*/
import { Experiense } from "./Experience";
import { Education } from "./Education";
import { Pricing } from "./Pricing";
import { Dna } from "react-loader-spinner";

function CV() {
	const location = useLocation();
	const navigate = useNavigate();

	/*Statement*/
	const [isLoading, setLoading] = useState(true);
	const [cvData, setCvData] = useState({});
	const [pricing, setPricing] = useState({});
	const [price, setPrice] = useState(false);
	const [canvasWidth, setCanvasWidth] = useState(window.innerWidth);
	const [player, setPlayer] = useState(false);
	const dateFormatting = (dateStamp) => {
		if (dateStamp) {
			let date, month, year;

			dateStamp.toString().length <= 10
				? (date = new Date(dateStamp * 1000))
				: (date = new Date(dateStamp));
			month =
				date.getMonth() + 1 < 10
					? "0" + (date.getMonth() + 1)
					: date.getMonth() + 1;
			year = date.getFullYear();

			return `${month}.${year}`;
		} else {
			return "N/A";
		}
	};

	function showPlayer(e) {
		e.target.classList.add(stl.hidden);
		setPlayer(true);
	}

	/*Transitional*/
	const skills = cvData.skills_string?.split(",");
	/*Effects*/
	useEffect(() => {
		axios({
			url: `https://crm-s.com/api/v1/employees${location.pathname}`,
			params: {
				global_company_name: "Remote Helpers",
			},
		})
			.then((response) => {
				setCvData(response.data.data.employee);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				navigate("/404");
			});
	}, []);
	useEffect(() => {
	    const pricingObj = {
	        full: {
	            name: "full-time",
	            symbol: cvData.full_symbol ? cvData.full_symbol : '',
	            price: cvData.full_price ? cvData.full_price : 'n/a',
	            hours: 160
	        },
	        part: {
	            name: "part-time",
	            symbol: cvData.full_symbol ? cvData.full_symbol : '',
	            price: cvData.part_price ? cvData.part_price : 'n/a',
	            hours: 80
	        },
	        min: {
	            name: "minimal",
	            symbol: cvData.full_symbol ? cvData.full_symbol : '',
	            price: cvData.min_price ? cvData.min_price : 'n/a',
	            hours: 40
	        }
	    }
	    setPricing(pricingObj)
	    // console.log('pricing object init =>', pricing)
	    setPrice(true)
	}, [cvData])
	useEffect(() => {
		function handleResize() {
			setCanvasWidth(window.innerWidth);
		}

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	if (isLoading) {
		return (
			<Dna
				visible={true}
				height="80"
				width="80"
				ariaLabel="dna-loading"
				wrapperStyle={{}}
				wrapperClass="dna-wrapper"
			/>
		);
	}

	console.info(cvData);
	// console.info('Location =>',location);

	return (
		<article className={stl.cv_canvas}>
			<section className={stl.general}>
				<div className={stl.heroSection}>
					{canvasWidth < 768 && (
						<div className={stl.meta}>
							<span>ID:{cvData.employee_id}</span>
							<span className={stl.status}>{cvData.status_name}</span>
							<span>{cvData.shift} shift</span>
						</div>
					)}
					<picture>
						<img
							src={cvData.image_url}
							alt={`${cvData.short_name} - ${cvData.position_name}`}
						/>
					</picture>
					{player && (
						<div className={stl.playerWrapper}>
							<ReactPlayer
								url={cvData.url_video_to_youtube}
								width="100%"
								height="unset"
								controls={true}
							/>
							<IoCloseCircleOutline onClick={() => setPlayer(false)} />
						</div>
					)}
					{canvasWidth < 768 && (
						<div className={stl.idents}>
							<h2>{cvData.short_name}</h2>
							<h3>{cvData.position_name}</h3>
						</div>
					)}
					{canvasWidth > 768 && (
						<div className={stl.desktopMeta}>
							<h2>{cvData.short_name}</h2>
							<h3>{cvData.position_name}</h3>
							<span>{cvData.shift} shift</span>
							<span className={stl.idNumber}>ID:{cvData.employee_id}</span>
						</div>
					)}
					{canvasWidth < 768 && !player ? (
						cvData.url_video_to_youtube ? (
							<button onClick={showPlayer} className={stl.videoBtn}>
								<IoPlayCircleOutline /> Watch video
							</button>
						) : null
					) : null}
				</div>
				{price && <Pricing pricing={pricing} data={cvData}/>}
				<div className={stl.skillSection}>
					<b>skills & tools</b>
					<div className={stl.skillArray}>
						{skills
							?.sort((a, b) => a.length - b.length)
							.map((item, index) => (
								<s key={index}>{item}</s>
							))}
					</div>
				</div>
			</section>
			<section className={stl.secondary}>
				{canvasWidth > 768 && cvData.url_video_to_youtube && (
					<ReactPlayer
						className={stl.desktopPlayer}
						url={cvData.url_video_to_youtube}
						width="100%"
						height="unset"
						controls={true}
					/>
				)}
				<Experiense
					data={cvData.work_experiences}
					dateFormatting={dateFormatting}
				/>
				<Education data={cvData.educations} dateFormatting={dateFormatting} />
			</section>
		</article>
	);
}

export { CV };
